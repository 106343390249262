import styled from 'styled-components';
import TextInput from 'react-autocomplete-input';

export const Container = styled.div`
  min-height: 692px;
  height: 1300px;
  // position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  // overflow: hidden;
  background: url("https://wallpapers.com/images/hd/spider-man-into-the-spider-verse-purple-fanart-bfvcyzjncbf03lxb.webp") no-repeat center center;
  background-size: cover;

  @media screen and (max-width: 480px) {
    background: url("https://iphoneswallpapers.com/wp-content/uploads/2019/02/SpiderMan-Into-the-Spider-Verse-iPhone-Wallpaper.jpg") no-repeat center center;
    background-size: cover;
  }

  @media screen and (max-height: 1000px) {
    height: 1000px;
  }
`
export const FormWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 80%;
  }
`

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`

export const Form = styled.form`
  background-color: rgba(100, 50, 138, 0.3);
  max-width: 400px;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;
  border-radius:4px;
  box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.9);
  
  @media screen and (max-width: 480px) {
    max-width: 90%;
    padding: 32px 32px;
  }
`

export const Hidden = styled.div`
  background-color: rgba(100, 50, 138, 0.3);
  max-width: 600px;
  width: 100%;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;
  border-radius:4px;
  box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.9);
  
  @media screen and (max-width: 480px) {
    max-width: 90%;
    padding: 32px 32px;
  }
`

export const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: rgba(235, 235, 255, 0.9);
  font-family: ui-rounded;
  text-align: center;
  font-size: 3.5rem;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 3rem;
  }
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 3px #000
`
export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border-radius: 4px;
`

export const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  border-radius: 15px;
  color: #fff;
  font-size: 1.85rem;
  cursor: pointer;
  font-family: ui-rounded;
  text-align: center;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(235, 235, 255, 0.8);
    border-radius: 30px;
    color: #000b;
  }
`

export const Text = styled.span`
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
`

// https://www.npmjs.com/package/react-autocomplete-input
export const TextInputSt = styled(TextInput)`
  padding: 16px 16px;
  margin-bottom: 32px;
  border-radius: 4px;
  ackground: papayawhip;
`