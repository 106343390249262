import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 1300px;
  position: relative;
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 
      linear-gradient(180deg, rgba(0,0,0,0.2)0%, rgba(0,0,0,0.6)100%),  
      linear-gradient(180deg, rgba(0,0,0,0.2)0%, transparent 100%)
    z-index: 2;
  }

  @media screen and (max-height: 1000px) {
    height: 1000px;
  }
`
export const Bg = styled.div`
  background-image: url(${({img}) => (img)});
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  filter: opacity(85%);

  @media screen and (max-width: 800px) {
    background-image: url("https://i.redd.it/bx6gwfbl41a41.jpg");
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    filter: opacity(95%);
  }
`

export const TextWrapper = styled.div`
    padding-top: 0;
    padding-bottom: 60px;
    align-items: center;
`

export const Content = styled.div`
  background: rgba(100, 50, 138, 0.3);
  z-index: 3;
  max-width: 90%;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 10px;
  box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.9);

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(100, 50, 138, 0.8);
  }
`

export const Text = styled.p`
  font-size: 2rem;
  text-align: ${({left}) => (left ?'left' : 'center') };
  color: #fff;
  max-width: 100%;

  @media screen and (max-width: 480px) {
      font-size: 1.3rem;
  } 
`

export const ScheduleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 10px;
`
