import React from 'react'
import { Loader } from "@googlemaps/js-api-loader"
import { LocationMap, Container} from './LocationElements'
import { H1, H2, Wrapper, TextWrapper, P} from '../SharedElements'

let map;

const loader = new Loader({
  apiKey: "AIzaSyBGz29Uqt-Vw44uR11aeZORM9LJzQYQxmE",
  version: "weekly",
});


const Location = () => {
  return (
    <Container id='location'>
      <H1>Location</H1>
      <Wrapper>
        <TextWrapper>
          <H2 light={true}>Kerrisdale Community Center</H2>
          <P light={true}>5851 West Blvd, Vancouver </P>
          <P light={true}>Party Room: Auditorium, Room 109 </P>
        </TextWrapper>
      </Wrapper>
      <LocationMap id='map' />
    </Container>
  )
}

export default Location

loader.load().then(async () => {
  const { Map } = await window.google.maps.importLibrary("maps");

  var cc = new window.google.maps.LatLng(49.23320309089625, -123.15669471153242);

  var infoWindow = new window.google.maps.InfoWindow();

  map = new Map(document.getElementById("map"), {
    center: cc,
    zoom: 18,
  });

  const fullAddress = '<div style="color:Black;"><h3>Kerrisdale Community Center</h3>' +
    '</p>5851 West Blvd</p>Vancouver, BC V6M 3W9</p> Canada</p></div>' +
    '<a href="https://www.google.com/maps?ll=49.233203,-123.156695&z=18&t=m&hl=en-CA&gl=US&mapclient=apiv3&cid=193931646418659264" target="_blank">View on Google Maps</a>'

  var marker = new window.google.maps.Marker({
    position: cc,
    map: map});
  infoWindow.setContent(fullAddress);
  infoWindow.open(marker.getMap(), marker);

  /* Example search for places
  var request = {
    query: address,
    fields: ['name', 'geometry', 'formatted_address'],
  };

  var service = new window.google.maps.places.PlacesService(map);

  service.findPlaceFromQuery(request, function(results, status) {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      for (var i = 0; i < results.length; i++) {
        console.log(results[i])
        var marker = new window.google.maps.Marker({
            position: results[i].geometry.location,
            map: map, 
            title: "Kerrisdale Community Centre\n5851 West Blvd\nVancouver, BC V6M 3W9\nCanada"
        });
        infoWindow.setContent(marker.getTitle());
        infoWindow.open(marker.getMap(), marker);
      }
      map.setCenter(results[0].geometry.location);
    }
  });*/

});
