import styled from 'styled-components'

export const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 20px;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    align-items: center;
`

export const H1 = styled.h1`
    font-size: 3.5rem;
    // color: #01bf71;
    color: rgba(235, 235, 255, 0.9);
    font-family: ui-rounded;
    text-align: center;
    margin-bottom: 40px;
    align-items: center;

    @media screen and (max-width: 480px) {
        font-size: 3rem;
    } 
`

export const H2 = styled.h2`
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
    color: ${({light}) => (light ? '#fff' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`

export const P = styled.p`
    font-size: 2rem;
    text-align: center;
    color: ${({light}) => (light ? '#fff' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    } 
`