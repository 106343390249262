import React, {useState} from 'react'
import { Container, FormWrap, Hidden, FormContent, Form, FormInput, FormH1, FormLabel, FormButton, TextInputSt } from './JoinElements'
import './AutoCompleteTextField.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGz29Uqt-Vw44uR11aeZORM9LJzQYQxmE",
  authDomain: "jlnote.firebaseapp.com",
  databaseURL: "https://jlnote.firebaseio.com",
  projectId: "jlnote",
  storageBucket: "jlnote.appspot.com",
  messagingSenderId: "278654668486",
  appId: "1:278654668486:web:93c4f0a0f92986b3b851c7",
  measurementId: "G-JR9GEDRCEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function setGuestRegistation(email, guestName, numberOfChild, numberOfAudult) {
  const docRef = await addDoc(collection(db, "guests"), {
    email: email,
    guestName: guestName,
    numberOfChild: numberOfChild,
    numberOfAudult : numberOfAudult,
    dateime: new Date().toISOString()
  });
  console.log("Document written with ID: ", docRef.id);
}

const guest_list = [
]

const Join = () => {
  const [isSubmit, setIsSubmit] = useState(true)

  async function handleSubmit(event) {
    event.preventDefault();
    // alert('A name was submitted: ' + event.currentTarget.guestName.value);
    await setGuestRegistation(event.currentTarget.email.value, event.currentTarget.guestName.value, event.currentTarget.numberOfChild.value, event.currentTarget.numberOfAudult.value);
    setIsSubmit(true)
  }

  function handleEmailChanged(event) {
    event.preventDefault();
    console.log('Email updated: ' + event.currentTarget.value);
  }
  
  return (
      <Container id='join'>
        <FormWrap>
          <FormContent>
            {(() => {
              if (isSubmit) {
                return (
                <Hidden>
                  <FormH1>Register is now closed. </FormH1>
                  <FormH1>Thank you!</FormH1>
                </Hidden>)
              }
              else {
                return (<Form onSubmit={handleSubmit}>
                  <FormH1>Register</FormH1>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                    <FormInput id='email' type='email' onBlur={handleEmailChanged} required/> 
                  <FormLabel htmlFor='guestName'>Child's Name</FormLabel>
                    <TextInputSt id='guestName' options={guest_list}  trigger="" Component="input" required/>
                  <FormLabel htmlFor='numberOfChild'>Number of Children</FormLabel>
                    <FormInput id='numberOfChild' type="number" defaultValue="1" required/>
                  <FormLabel htmlFor='numberOfAudult'>Number of Adults</FormLabel>
                    <FormInput id='numberOfAudult' type="number" defaultValue="1" required/>
                  <FormButton type='submit'>Submit</FormButton>
                </Form>)
            }})()}
          </FormContent>
        </FormWrap>
      </Container>
  )
}

export default Join
