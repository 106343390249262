import React, {useState} from 'react'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Location from '../components/Location';
import Date from '../components/Date';
import Sidebar from '../components/SideBar'
import Join from '../Join';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <Location />
      <Date />
      <Join />
    </>
  )
}

export default Home
