import React from 'react'
import { Container, Bg, Content, TextWrapper, Text, ScheduleContainer } from './DateElements'
import ElsaImg from '../../images/Elsa.png'
import {H1, H2, Wrapper, P} from '../SharedElements'

const Date = () => {
  return (
      <Container id='date' >
        <Bg img={ElsaImg}/>
        <Content>
            <TextWrapper>
              <H1>Date and Schedule</H1>
              <P light={true}>October 14, 2023</P><br/>
              <ScheduleContainer>
              <Text>11:00 - 12:00</Text><Text left={true}>Auditorium for playtime</Text>
              <Text>12:00 - 13:00</Text><Text left={true}>Room 109 for food & Cake</Text>
              </ScheduleContainer>
              <br/>
              <Text>Secret guests will join the party at 11:30</Text>
            </TextWrapper>
        </Content>
      </Container>
  )
}

export default Date
