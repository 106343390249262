import styled from 'styled-components'

export const Container = styled.div`
  height: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: url("https://images.hdqwalls.com/wallpapers/spiderman-in-new-york-7p.jpg") center center/cover no-repeat;
  
    //"https://img.freepik.com/free-photo/beautiful-purple-universe-background_1017-3207.jpg?w=2000&t=st=1693288518~exp=1693289118~hmac=c0f60a2a65f13624254aee0e37c90af066842d963293de974207f3168d609f81") center center/cover no-repeat;

  @media screen and (max-height: 1000px) {
    height: 1000px;
  }

  @media screen and (max-width: 768px) {
    height: 900px;
  }
 `

export const LocationMap = styled.div`
  margin: 0 auto;
  align-items: center;
  height: 500px;
  width: 1000px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 700px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-height: 300px;
    max-width: 300px;
  }
`


